<template>
  <div style="padding-top: 50px;width: 100%;">
    <LeftTitle title="服务客户"/>
    <div class="service-customer-css">
      <div class="animated fadeInUp">
        <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/service.png" alt="客户">
      </div>
    </div>
  </div>
</template>

<script>
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  components: {LeftTitle},
  setup() {
    return {}
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
}

</script>

<style lang='less' scoped>
.service-customer-css {
  padding-left: 10vh;
  padding-right: 10vh;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
